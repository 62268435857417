<template>
  <section class="tv-adv-two-section padd-top-80">
    <div class="tv-heading-title text-center">
      <h2>What makes <span>us better?</span></h2>
    </div>
    <div class="tv-adv-two-con">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-sm-6 width-50 tv-adv-two-sub1 text-center" v-for="(item, index) in features" :key="index">
            <div class="tv-img-icon">
              <img  :src="item.src">
            </div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.desc }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'UsBetterSection',
  data() {
    return {
      features: [
        {
          src: require('@/assets/img/Image9.png'),
          title: "Equipment",
          desc: "我们拥有先进的计算机实验室，4090等高算力显卡，人手一台高性能计算机"
        },
        {
          src: require('@/assets/img/Image10.png'),
          title: "Instructors",
          desc: "我们有强大的师资力量"
        },
        {
          src: require('@/assets/img/Image11.png'),
          title: "Locations",
          desc: "地理位置优越，位于大湾区"
        },
        {
          src: require('@/assets/img/Image12.png'),
          title: "Research",
          desc: "前沿技术"
        }
      ]
    }
  }
}
</script>

<style scoped>
/* 您可以在这里添加组件特定的样式 */

/* 示例样式 */
.tv-adv-two-sub1 {
  padding: 20px;
  margin-bottom: 20px;
}
</style>
