<template>
  <section id="teachers" class="container padd-top-80">
    <div class="tv-heading-title text-center">
      <h2> 导师  <span>成员</span></h2>
    </div>
    <div class="row">
      <mentor-card v-for="mentor in mentors" :key="mentor.name" :mentor="mentor" />
    </div>
  </section>
</template>
<script>
import MentorCard from './MentorCard.vue';

export default {
  name: 'Mentors',
  components: {
    MentorCard,
  },
  data() {
    return {
      mentors: [
        // 假设的导师数据
        {
          name: '冯跃',
          qualification: '博士',
          introduction: '从事智能技术研发三十余年，长期工作在大型企业并参与大学合作的科研项目， 具有较强的基础应用研究以及带领团队进行科研和开发的能力。现为五邑大学智能制造学部特聘教授， 硕士生导师，五邑大学智能医学实验室负责人， 国家外国专家局认可的外国高端人才（A类），江门市高层次人才。',
          image:  require('@/assets/img/冯跃.png'),
          researchFindingsUrl:''
        }
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // 在这里调用后端API获取数据
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/teacher/getAllTeacher')
          .then((res) => {
            // 处理响应数据
            this.mentors = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });
    },
  }



};
</script>
<style scoped>




</style>
