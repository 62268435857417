import { render, staticRenderFns } from "./makeUsBetter.vue?vue&type=template&id=64d591bf&scoped=true"
import script from "./makeUsBetter.vue?vue&type=script&lang=js"
export * from "./makeUsBetter.vue?vue&type=script&lang=js"
import style0 from "./makeUsBetter.vue?vue&type=style&index=0&id=64d591bf&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64d591bf",
  null
  
)

export default component.exports