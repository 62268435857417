<template>
  <section id="programs" class="tv-projects-section padd-top-80">
    <div class="tv-heading-title text-center">
      <h2>Our <span>projects</span></h2>
    </div>

    <div class="tv-projects">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <div class="post1-bg" :style="{'background-image': `url(${projects[0].image})`}" @click="openDialog(0)">
              <div class="post-contain">
                <div class="post-text">
                  <h3>{{ projects[0].name }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="row tv-programs1">
              <!-- 动态生成项目2到项目6 -->
              <div v-for="(program, index) in projects.slice(1)" :key="index" class="col-md-6 col-sm-6">
                <div :class="`post${index+2}-bg`" :style="getPostStyle(index+1)" @click="openDialog(index+1)">
                  <div class="post-contain1">
                    <div class="post-text">
                      <h3>{{ program.name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      <!-- Dialog 弹窗 -->
      <el-dialog :visible.sync="dialogVisible" :title="currentProgram.name">
        <img :src="currentProgram.image" class="dialog-image">
        <div class="dialog-content">
          <h4>项目描述:</h4>
          <p>{{ currentProgram.date }}</p>
          <p>{{ currentProgram.introduction }}</p>
          <!-- 这里可以添加更多项目详细信息 -->
        </div>
      </el-dialog>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ProgramsSection',
  data() {
    return {
      projects: [
        { name: '' ,image: '', introduction: '', date: '' ,sequence: ''},
      ],
      dialogVisible: false,
      currentProgram:{ name: '' ,image: '', introduction: '', date: '' ,sequence: ''}, // 初始化默认值
    };
  },
  created() {
    this.getProjectData();
  },
  methods: {
    getPostStyle(index) {
      return { 'background-image': `url(${this.projects[index].image})` };
    },
    getProjectData(){
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/project/getAllProject')
          .then((res) => {
            // 处理响应数据
            this.projects = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });
    },
    openDialog(index) {
      this.currentProgram = this.projects[index];
      this.dialogVisible = true;
    }
  }
};
</script>

<style scoped>
.project-card {
  cursor: pointer;
  height: 400px;
}
.dialog-image {
  max-width: 100%; /* 或者设置一个固定的最大宽度，如 max-width: 300px; */
  max-height: 200px; /* 设置最大高度，根据需要调整 */
  object-fit: cover; /* 保持图片比例 */
  margin-bottom: 15px; /* 添加底部边距 */
}
.dialog-content {
  font-size: 16px; /* 根据需要调整字体大小 */
  line-height: 1.5; /* 增加行高 */
  padding: 15px; /* 增加内边距 */
}

.dialog-content h4 {
  margin-bottom: 10px; /* 增加标题与内容之间的间距 */
  font-weight: bold; /* 加粗标题 */
}

.dialog-content p {
  margin: 0 0 15px; /* 段落间距 */
}
</style>
