<template>
  <section id="contactus" class="tv-contactus-section padd-top-80 padd-bot-80">
    <div class="tv-heading-title text-center">
      <h2>contact <span>us</span></h2>
    </div>
    <div class="tv-contactus-contain">
      <div class="container">
        <form @submit.prevent="handleSubmit" class="tv-contact-form">
          <!-- 使用v-model来实现双向数据绑定 -->
          <input type="text" placeholder="Name :" required="" class="form-control" v-model="name">
          <input type="email" placeholder="Email Id :" required="" class="form-control" v-model="EmailId">
          <input type="text" placeholder="Moblie No :" required="" class="form-control" v-model="MobileNo">
          <textarea class="form-control" rows="5" v-model="Message" placeholder="Message : ">Message : </textarea>
          <button type="submit" class="btn btn-block">SEND</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactUs',
  data() {
    return {
      name: '',
      EmailId: '',
      MobileNo: '',
      Message: ''
    };
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      // 在这里添加表单提交的逻辑
      const Email = {name:this.name, EmailId:this.EmailId, MobileNo:this.MobileNo, Message:this.Message};
      console.log(Email);
      let data = {
        name: this.name,
        EmailId: this.EmailId,
        MobileNo: this.MobileNo,
        Message: this.Message
      };
      // 使用 axios 发送 POST 请求到后端
      this.$axios.post('https://endpointitcmserver.imedlabwyu.com:9002/web_server/email/sendEmail', {
        name: this.name,
        emailId: this.EmailId,
        mobileNo: this.MobileNo,
        message: this.Message
      })
          .then(response => {
            // 处理成功响应
            if (response.data === 1) {
              this.$notify({
                title: '成功',
                message: '信息发送成功',
                type: 'success'
              });
              this.name = '',
              this.EmailId= '',
              this.MobileNo= '',
              this.Message= ''
            }else {
              this.$notify({
                title: '错误',
                message: '信息发送失败,请稍后重试',
                type: 'error'
              })
            }
            console.log(response.data);
          })
          .catch(error => {
            // 处理错误响应
            console.error(error);
          });
    }
  }
};
</script>

<style scoped>
.btn{
  width: 100%;
  background-color:#82b74b;
  color: #ffffff;
  border-radius: 0;
  margin-top: 20px;
  height:45px;
  font-weight: bold;
}
.btn:hover{
  border: 2px solid #82b74b;
  color: #82b74b;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
}
.btn.active.focus, .btn.active:focus, .btn.focus, .btn.focus:active, .btn:active:focus, .btn:focus {
  outline: none; }
/* 在这里添加任何自定义样式 */
</style>
