<template>
  <footer>
    <div class="footer-bg" :style="{ backgroundImage: 'url(' + footerImageUrl + ')' }">
      <div class="tv-footer-contain">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-sm-12"a>
              <div class="tv-footer-heading-one tv-footer-wrap">
                <div class="tv-footer-heading">
                  <h2>IMED<span>LAB</span></h2>
                </div>
                <p>
                  <br>
                  <br>
                  <br>
                </p>
                <div class="tv-social-icon">
                  <a href=""><i class="fa fa-facebook"></i></a>
                  <a href=""><i class="fa fa-twitter"></i></a>
                  <a href=""><i class="fa fa-google-plus"></i></a>
                  <a href=""><i class="fa fa-instagram"></i></a>
                  <a href=""><i class="fa fa-youtube"></i></a>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="tv-footer-subcontain-two tv-footer-wrap">
                <h3>We Are Open</h3>
                <div class="tv-time">
                  <p>Mon-Sun : 8:30 -22:00</p>
                  <p>Closed on holidays</p>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-sm-6">
              <div class="tv-footer-subcontain-three tv-footer-wrap">
                <h3>Contact Us</h3>
                <div class="tv-info">
                  <p>广东省江门市蓬江区东成村22号综合实验楼510</p>
<!--                  <p><a href="tel:18318889759">18318889759</a></p>-->
                  <a href="mailto:j002443@wyu.edu.cn">j002443@wyu.edu.cn</a>
                </div>
              </div>
            </div>
          </div>
          <div class="tv-footer-copyright text-center">
            <p>@Copyright 2024 冯跃课题组 . Designed By Bright</p>
            <p>
              <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024261861号</a>
              <span>&nbsp; &nbsp;
                <img style="width: 1rem" src="@/assets/img/beian.png" alt="粤公网安备44070502000638">粤公网安备
                <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44070502000638" rel="noreferrer" target="_blank">44070502000638</a>
              </span>
            </p>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      footerImageUrl: require('@/assets/img/Image36.jpg') // 确保这是正确的路径
    };
  }
};
</script>

<style scoped>
/* 这里可以添加CSS样式 */
</style>
