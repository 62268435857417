<template>
  <div class="mentor-card col-md-6 col-lg-6">
    <div class="d-flex">
      <div class="avatar-side">
        <img :src="mentor.image"  class="mentor-avatar" :alt="mentor.name">
      </div>
      <div class="info-side">
        <div class="card-body">
          <h5 class="card-title">{{ mentor.name }}</h5>
          <h3 class="card-text">{{ mentor.qualification }}</h3>
          <div class="intro-container" @mouseenter="showFullIntro = true" @mouseleave="showFullIntro = false">
            <p class="card-text intro" :class="{ 'expanded': showFullIntro }">{{ mentor.introduction }}</p>
            <div v-if="showFullIntro" class="tooltip-content">
              <div class="tooltip-text">{{ mentor.introduction }}</div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="btn-group">
              <button type="button"  class="btn btn-sm btn-outline-secondary"><a target="_blank" :href="mentor.researchFindingsUrl" style="text-decoration: none; color: #9a9696">查看研究成果</a></button>
            </div>
          </div>
        </div>
      </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'MentorCard',
  props: {
    mentor: {
      type: Object,
      default: () => ({
        name: '张三',
        degree: '博士',
        bio: '张三教授，博士生导师，研究方向包括...',
        avatar: '@/assets/img/李嘉.png',
      }),
    },
  },
  data() {
    return {
      showFullIntro: false
    };
  },
};
</script>

<style scoped>
@import "@/assets/css/style.css";
h3{
  font-weight: 600;
  color: #82b74b;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.mentor-card{
  margin: 20px 0;
}
.mentor-avatar {
  width: 120px;  /* 头像宽度填满容器 */
  height: 150px; /* 头像高度填满容器 */
  object-fit: cover; /* 裁剪并覆盖整个头像区域 */
  border-radius: 50%; /* 圆角，创建椭圆形效果 */
  border: 4px solid #fff; /* 添加白色边框 */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* 添加阴影 */
  margin-right: 20px; /* 头像和文本之间的间距 */
}
.avatar-side {
  flex: 0 0 auto; /* 防止头像宽度随屏幕大小变化 */
  width: 120px;   /* 头像容器宽度 */
  height: 150px;  /* 头像容器高度 */
}

.info-side {
  flex: 1; /* 确保信息部分占据剩余空间 */
}
.card-body {
  padding: 0; /* 根据需要调整内边距 */
}
.btn-group {
  margin-top: 10px; /* 按钮与文本之间的间距 */
}

.intro-container {
  position: relative;
  display: inline-block;
}
.tooltip-content {
  visibility: hidden;
  //width: max-content; /* Ensures the tooltip is as wide as needed */
  width: 400px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 25%;
  opacity: 0;
  transition: opacity 0.3s;
}

.intro-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
  transform: translateX(-50%) translateY(90%); /* 微调弹出窗口的位置，让它看起来像真正弹出 */
}
/* 确保弹出窗口有足够的空间显示文本 */
.tooltip-text {
  word-wrap: break-word; /* 确保长单词也能换行 */
}
/* Optional: Add this class if you want the tooltip to expand the text instead of showing it in a new area */
.expanded {
  /* Add styling for the expanded text here, if needed */
}
.intro{
  max-height: 100px;
  height: 100px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 超出几行省略 */
}
</style>
