<template>
  <div id="postgraduate" class="student-gallery padd-top-80 col-md-4 col-lg-10">

    <div class="tv-heading-title text-center">
      <h2>科研<span>助理</span></h2>
    </div>
    <div class="d-flex align-items-center justify-content-center flex-wrap ">
      <div
          v-for="(student, index) in students"
          :key="index"
          class="student-thumbnail"
          style="flex: 0 0 12.5%; position: relative;"
          @mouseover="showName(index)"
          @mouseleave="hideName"
      >
        <img :src="student.image" :alt="student.name" class="student-avatar">
        <div class="student-year" v-show="showNameIndex === index">{{ student.year }}</div>
        <div class="student-name" v-if="showNameIndex === index">{{ student.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.getStudentData();
  },
  name: 'StudentGallery',
  data() {
    return {
      students: [
        {
          "studentId": "2112304034",
          "name": "孙千帅",
          "year": "2023",
          "graduated": "0",
          "image": "https://j002443-itcm.oss-cn-guangzhou.aliyuncs.com/student/2024/207b5e2e9d7845c38cf7317f534339d2孙千帅.jpg"
        },
        // ...其他学生数据
      ],
      showNameIndex: -1, // 用于跟踪当前显示名字的学生索引
      timeoutId: null, // 新增: 用于存储 setTimeout 的 ID
    };
  },

  methods: {
    showName(index) {
      this.showNameIndex = index;
      // 清除之前的定时器
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.showNameIndex = -1;
      }, 1500); // 3000 表示 3 秒
    },
    hideName() {
      this.showNameIndex = -1;
    },

    getStudentData(){
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/student/getResearchAssistant')
          .then((res) => {
            // 处理响应数据
            this.students = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });
    }


  }
}
</script>

<style scoped>
.student-gallery {
  margin: auto; /* 居中容器 */
}

.student-thumbnail {
  margin: 10px; /* 头像之间的间距 */
  cursor: pointer;
  text-align: center; /* 确保文本在中心 */
}

.student-avatar {
  display: block;
  width: 150px;
  height: 150px;
  transition: transform 0.3s ease-in-out; /* 平滑缩放效果 */
}
.student-name {
  position: absolute; /* 绝对定位 */
  top: 88%; /* 从顶部开始定位 */
  left: 43%; /* 从左侧开始定位 */
  transform: translate(-50%, -50%); /* 将名字移动到图片中心 */
  color: #fff; /* 文字颜色 */
  font-weight: bold; /* 加粗文字 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  padding: 5px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  width: 80%; /* 名字宽度 */
  text-align: center; /* 文本居中 */
  display: none; /* 默认不显示 */
}
.student-year {
  position: absolute; /* 绝对定位 */
  top: 12%; /* 从顶部开始定位 */
  left: 43%; /* 从左侧开始定位 */
  transform: translate(-50%, -50%); /* 将名字移动到图片中心 */
  color: #fff; /* 文字颜色 */
  font-weight: bold; /* 加粗文字 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  padding: 5px; /* 内边距 */
  border-radius: 5px; /* 圆角 */
  width: 50%; /* 名字宽度 */
  text-align: center; /* 文本居中 */

}
.student-avatar:hover {
  transform: scale(1.3); /* 鼠标悬停时放大 */
}

.student-thumbnail:hover .student-name {
  display: block; /* 鼠标悬停时显示名字 */
}
</style>
