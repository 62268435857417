<template>
  <div id="thesis" class="container mt-5 padd-top-80">
    <div class="tv-heading-title text-center">
      <h2>论文<span>成果</span></h2>
    </div>
    <div class="row g-4">
      <!-- 只展示前两篇论文 -->
      <div class="col-md-6" v-for="paper in displayedPapers" :key="paper.id">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ paper.title }}</h5>
            <h6 class="card-subtitle mb-2 text-muted">&nbsp;{{ paper.authors }}</h6>
            <p class="card-text">&emsp; &emsp; {{ paper.summary }}</p>
            <small class="text-muted">{{ paper.keywords }}</small>
          </div>
          <div class="card-footer text-muted">
            Published on: {{ paper.date }}

            <a :href="paper.url" target="_blank" class="float-end"><i class=" float-end fa fa-search" aria-hidden="true" /></a>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看更多按钮 -->
    <div class="text-center mt-4">
      <button class="btn btn-success" @click="loadMorePapers"  :style="{ backgroundColor: loadedAll ? 'grey' : '#82b74b', borderColor: '#82b74b' }"  :disabled="loadedAll">更多</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResearchPapers',
  data() {
    return {
      displayedPapers: [], // 初始为空，稍后填充
      papers: [], // 所有论文数据，假设这是从API获取的
      loadedAll: false // 标记是否已加载所有论文
    };
  },
  created() {
    this.fetchData(); // 组件创建时获取数据
  },
  methods: {
    fetchData() {
      // 在这里调用后端API获取数据
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/papers/getAllPapers')
          .then((res) => {
            // 处理响应数据
            this.papers = res.data;
            this.displayedPapers = res.data.slice(0, 2);
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });
    },
    loadMorePapers() {
      if (this.papers && !this.loadedAll) {
        try {
          const additionalPapers = this.papers.slice(this.displayedPapers.length, this.displayedPapers.length + 2);
          this.displayedPapers.push(...additionalPapers);
          if (this.displayedPapers.length >= this.papers.length) {
            this.loadedAll = true;
          }
        } catch (error) {
          console.error('Error loading more papers:', error);
          // 可以在这里添加更多的错误处理逻辑，比如显示一个错误消息
        }
      }
    }
  }
};
</script>

<style scoped>
/* 自定义样式 */
.card-body {
  min-height: 300px; /* 根据需要调整高度 */
}
.btn{
  width: 100%;
}
</style>
