<template>
  <!-- 合作伙伴评价 -->
  <section id="partners" class="tv-testimonials-section padd-top-80">
    <div class="tv-test-bg">
      <div class="container testimonials">
        <div class="tv-testimonials-title text-center">
          <h2>Partner<span></span></h2>
        </div>
        <!-- 上方评价内容 -->
        <div class="evaluation">
<!--          <p class="evaluation-text">{{ currentEvaluation }}</p>-->
          <div class="hospital-name text-center">
            <h3>——  江门市中心医院医生团队</h3>
            <h3>——  江门市妇㓜保健院医生团队</h3>
            <h3>—— 上海中医药大学李福凤教授团队</h3>
          </div>
        </div>

<!--        &lt;!&ndash; 下方图标导航 &ndash;&gt;-->
<!--        <div class="indicators">-->
<!--          <button-->
<!--              v-for="(hospital, index) in hospitals"-->
<!--              :key="index"-->
<!--              :class="{ active: index === activeIndex }"-->
<!--              @mouseenter="setActiveHospital(index)"-->
<!--          >-->
<!--            <img :src="hospital.logo" :alt="hospital.name" class="hospital-logo">-->
<!--          </button>-->
<!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HospitalEvaluationCarousel',
  data() {
    return {
      activeIndex: 0,
      hospitals: [
        {
          logo: require('@/assets/img/Image29.png'),
          evaluation: '智能医学实验室是是一个优秀的实验室，冯跃教授是一个优秀的教授',
          name: '江门市中心医院'
        },
        {
          logo: require('@/assets/img/Image30.png'),
          evaluation: '目前与冯跃教授开展的变应性鼻炎项目非常顺利，我们对于未来的成果抱有很大的希望',
          name: '江门市妇幼保健院'
        },
        {
          logo: require('@/assets/img/Image31.png'),
          evaluation: '冯跃教授与我们联合开展的智能中医项目十分出色，展现了冯跃教授团队的执行力',
          name: '上海中医药大学'
        }
        // ...其他医院数据...
      ]
    };
  },
  computed: {
    currentEvaluation() {
      return this.hospitals[this.activeIndex].evaluation;
    },
    currentHospitalName() {
      return this.hospitals[this.activeIndex].name;
    }
  },
  methods: {
    setActiveHospital(index) {
      this.activeIndex = index;
    }
  }
};
</script>

<style scoped>
.tv-testimonials-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
}
.tv-test-bg
{
  background: url('@/assets/img/Image28.png') no-repeat center center;
  background-size: cover;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}
.tv-test-bg:after{
  background: rgba(0,0,0,0.6);
  content: ' ';
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: 100%;
  width: 100%;
}
.container {
  text-align: center;
}

.evaluation {
  margin-bottom: 40px; /* 根据需要调整 */
  margin-top: 40px; /* 根据需要调整 */
}

.evaluation-text {
  margin-bottom: 20px; /* 根据评价和图标之间的间距调整 */
  color: white;
  font-weight: bold;
  font-size: medium;
}
.hospital-name{
  margin-bottom: 20px; /* 根据需要调整 */
  color: #82b74b;
  font-size: larger;
  font-weight: bold;
}
.indicators {
  display: flex;
  justify-content: center;
  gap: 20px; /* 图标之间的间隔 */

}

.indicators button {
  border: none;
  background: none;
  padding: 0;
  margin: 0 5px; /* 调整图标之间的间距 */
  cursor: pointer;
  outline: none; /* 点击时不显示轮廓 */
}


.hospital-logo {
  width: 80px; /* 图标大小 */
  height: auto;
  border-radius: 10px; /* 添加圆角 */
  transition: border 0.3s ease; /* 平滑过渡效果 */
}

.active .hospital-logo,
.hospital-logo:hover {
  transform: scale(1.2); /* 放大选中或鼠标悬浮的图标 */
  border: 3px solid #4CAF50; /* 设置绿色边框 */
}
</style>
