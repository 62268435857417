<template>
  <section id="recruitment" class="tv-projects-section padd-top-80">
    <div>
        <div class="col-md-12 col-sm-12">
          <div class="tv-heading-title text-center">
            <h2>课题组<span>招生信息</span></h2>
          </div>
          <div class="container">
            <div class="recruitment">
              <p>
                课题组欢迎热爱科研、无论面对任何主观或客观原因都能坚持自己既定研究方向终生不渝的本科生、硕士研究生加入我们。
              </p>
              <h2>研究生招生方向</h2>
              <ul>
                <li>信息与通信</li>
                <li>模式识别与智能系统</li>
                <li>电子信息</li>
              </ul>
              <p>
                我们特别欢迎具有计算机科学或生物医学工程背景的本科生同学报考。
              </p>
              <h2>博士生招生项目</h2>
              <p>
                博士生将注册入澳大利亚的 PhD Program，满足毕业要求后由澳大利亚的大学颁发毕业学位文凭。该项目向优秀博士生提供免学费奖学金。
              </p>
              <p>进入博士项目的基本要求：</p>
              <ol>
                <li>IELTS（雅思） overall > 6.5, no individual band below 6.0。</li>
                <li>准备一篇 Research proposal，以展示您的研究动机和目标。</li>
                <li>以第一作者、共同第一作者、或通信作者发表科研论文累计 Impact Factor ＞5。</li>
                <li>拥有计算机科学和智能医学相关专业知识的硕士学位，具备编程能力（python、C++等），以及机器学习算法研发经验。</li>
                <li>良好的沟通与协调能力和团队合作意识，较强的责任感及进取精神。</li>
                <li>具有自我学习能力和意愿，吃苦耐劳和甘于奉献的意志品质。</li>
              </ol>
            </div>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'RecruitmentComponent',
  // 可以在这里添加组件的数据和方法
}
</script>

<style scoped>
.recruitment {
  margin: auto;
  text-align: justify;
  font-size: 20px;
  max-width: 800px;

}

h1, h2, h3 {
  text-align: center;
}

ul, ol {
  padding-left: 20px;
}

.contact-info p {
  margin: 10px 0;
}
</style>
