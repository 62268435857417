<template>
  <section id="researchFindings" class="tv-projects-section padd-top-80">
    <div class="tv-heading-title text-center">
      <h2>研究<span>成果</span></h2>
    </div>
    <div>
      <div class="col-md-12 col-sm-12">
        <div class="container">
          <div class="research-achievements">
            <!-- SCI 论文列表 -->
            <h3>SCI论文</h3>
            <div class="paper-category">
              <ul class="paper-list">
                <li style="list-style-type: none;padding-top: 20px" v-for="paper in sciPapers" :key="paper.name">
                  <a :href="paper.url" target="_blank">
                    {{ paper.name }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- 非SCI 论文列表 -->
            <h3>非SCI论文</h3>
            <div class="paper-category">
              <ol class="paper-list">
                <li style="padding-top: 20px" v-for="paper in nonSciPapers" :key="paper.name">
                  <a :href="paper.url" target="_blank">
                    {{ paper.name }}
                  </a>
                </li>
              </ol>
            </div>
            <!-- 专利列表 -->
            <h3>专利</h3>
            <div class="paper-category">
              <ol>
                <li style="padding-top: 20px" v-for="patent in patents" :key="patent.name">
                  <a :href="patent.url" target="_blank">
                    {{ patent.name }}
                  </a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResearchAchievements',
  data() {
    return {
      sciPapers: [
        // 包含论文标题和链接到出版社的网站的SCI论文列表
        { title: 'Example SCI Paper Title', link: 'http://www.publisher-sci-paper-link.com' },
        // ...更多SCI论文
      ],
      nonSciPapers: [
        // 包含论文标题和链接到出版社的网站的非SCI论文列表
        { title: 'Example Non-SCI Paper Title', link: 'http://www.publisher-nonsci-paper-link.com' },
        // ...更多非SCI论文
      ],
      patents: [
        // 包含专利号、专利标题和链接到专利网站
        { number: 'ZL202123456789.0', title: 'Example Patent Title', url: 'http://www.patent-link.com' },
        // ...更多专利
      ]
    };
  },
  created() {
    this.fetchData(); // 组件创建时获取数据
  },
  methods: {
    fetchData() {
      // 在这里调用后端API获取数据
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/research-findings/getAllSCIs')
          .then((res) => {
            // 处理响应数据
            this.sciPapers = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });

      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/research-findings/getAllNONSCIs')
          .then((res) => {
            // 处理响应数据
            this.nonSciPapers = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });

      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/research-findings/getAllPatents')
          .then((res) => {
            // 处理响应数据
            this.patents = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });

    },
  }
};
</script>

<style scoped>
.paper-category {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-y: scroll; /* 允许垂直滚动 */
  max-height: 400px; /* 最大高度您可以根据需要调整 */
}
/* 您可以添加一些样式来美化滚动条 */
.research-achievements .paper-category::-webkit-scrollbar {
  width: 14px; /* 滚动条的宽度 */
}
.research-achievements .paper-category::-webkit-scrollbar-thumb {
  background-color: #ccc; /* 滚动条的背景色 */
  border-radius: 4px; /* 滚动条的圆角 */
}
.research-direction h2 {
  text-align: center;
  margin-bottom: 20px;
}

.research-item {
  margin-bottom: 20px;
}

.research-item h3 {
  margin-bottom: 5px;
}

.research-item p {
  font-size: 16px;
  line-height: 1.6;
}
a {
  color: inherit; /* 链接文字的颜色与父元素相同 */
  text-decoration: none; /* 去除下划线 */
}
</style>
