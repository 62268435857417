
<template>
  <div class="home">
    <ScrollSpyNav></ScrollSpyNav>
    <aboutOurTeam></aboutOurTeam>
    <teacher></teacher>
    <student></student>
    <researchAssistant></researchAssistant>
    <ad1></ad1>
    <researchDirection></researchDirection>
    <partner></partner>
    <researchFindings></researchFindings>
    <ad2></ad2>
    <recruitment></recruitment>
    <contactUs></contactUs>
    <FooterComponent></FooterComponent>
  </div>
</template>


<script>
import ScrollSpyNav from '../views/navBar/ScrollSpyNav.vue'
import aboutOurTeam from '../views/aboutOurTeam/aboutOurTeam.vue'
import teacher from '../views/teacher/teacher.vue'
import student from '../views/student/student.vue'
import researchAssistant from '../views/researchAssistant/researchAssistant.vue'
import ad1 from '../views/ad/makeUsBetter.vue'
import ad2 from '../views/ad/dontWasteLife.vue'
import researchDirection from '../views/researchDirection/researchDirection.vue'
import programs from '../views/programs/programs.vue'
import partner from '../views/partner/partner.vue'
import researchFindings from '../views/researchFindings/researchFindings.vue'
import papers from '../views/papers/papers.vue'
import recruitment from '../views/recruitment/recruitment.vue'
import contactUs from '../views/contactUs/contactUs.vue'
import FooterComponent  from '../views/footer/footer.vue'

import $ from 'jquery';
export default {
  name: 'Home',

  components: {
    ScrollSpyNav,
    aboutOurTeam,
    teacher,
    ad1,
    student,
    researchAssistant,
    ad2,
    researchDirection,
    programs,
    partner,
    researchFindings,
    papers,
    recruitment,
    contactUs,
    FooterComponent,
  },
}
</script>


<style scoped>
@import "@/assets/css/style.css";
</style>
