<template>
  <section id="researchDirection" class="tv-projects-section padd-top-80">
    <div>
      <div class="col-md-12 col-sm-12">
          <div class="container">
            <div class="tv-heading-title text-center">
              <h2>研究<span>方向</span></h2>
            </div>
            <div class="research-direction">
              <div class="research-item" v-for="(item, index) in researchFields" :key="index">
                <h3>{{ item.name }}</h3>
                <p>{{ item.content }}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ResearchDirection',
  data() {
    return {
      researchFields: [
        {
          title: '智能医学诊疗与预后管理',
          content: '从机器学习的基本原理出发，研究可解释且具有强泛化能力的智能医学诊疗及预后管理方法。'
        }
      ]
    };
  },
  created() {
    this.fetchData(); // 组件创建时获取数据
  },
  methods: {
    fetchData() {
      // 在这里调用后端API获取数据
      this.$axios.get('https://endpointitcmserver.imedlabwyu.com:9002/web_server/research-direction/getAllRDs')
          .then((res) => {
            // 处理响应数据
            this.researchFields = res.data;
          })
          .catch(error => {
            // 处理错误情况
            console.error('Error fetching student data:', error);
          });
    },
  }
};
</script>

<style scoped>
.research-direction {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.research-direction h2 {
  text-align: center;
  margin-bottom: 20px;
}

.research-item {
  margin-bottom: 20px;
}

.research-item h3 {
  margin-bottom: 5px;
}

.research-item p {
  font-size: 16px;
  line-height: 1.6;
}
</style>
