<template>
  <div id="home" class="home-page">
    <!-- 背景图和导航栏 -->
    <div class="header-bg">
      <nav v-bind:class="{ 'bg-dark': isNavbarDark ,'bg-transparent': !isNavbarDark }" class="navbar fixed-top navbar-expand-lg navbar-light ">
        <div class="container">
          <a class="navbar-brand" href="#">
            <div class="logo logo_container">
              <img class="wyu_logo" src="@/assets/img/wyu2.png" alt="Logo">
                <div class="text-container">
                  <h3>智能医学实验室</h3>
                  <h5>Intelligent Medical Laboratory</h5>
                </div>
              </div>
          </a>
          <button style="background-color: #82b74b" class="navbar-toggler navbar-dark" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav nav text-left navbar-right ms-auto nav-custom">
                <li v-for="link  in navLinks" :key="link.id" class="nav-item" >
                  <a :href="link.href" class="nav-link white" :class="{ active: link.active }">{{ link.text  }}</a>
                </li>
              </ul>
            <a href="https://application.imedlabwyu.com/" target="_blank" class="nav-link white" >成果体验</a>
          </div>
        </div>
      </nav>
    </div>

    <!-- 五邑大学智能医学实验室冯跃课题组 -->
    <div class="banner-contain text-center">
      <div class="container">
        <h1>
          <span>冯跃课题组</span>
        </h1>
      </div>
    </div>
    <!-- 五邑大学智能医学实验室冯跃课题组 -->
  </div>
</template>

<script>
export default {
  name: 'HomePage',

  data() {
    return {
      navLinks: [
        { id: 'home', text: '首页', href: '#home', active: false },
        { id: 'teachers', text: '课题组成员', href: '#teachers', active: false },
        // { id: 'postgraduate', text: '研究生', href: '#postgraduate', active: false },
        // { id: 'programs', text: '研究方向', href: '#programs', active: false },
        // { id: 'partners', text: '合作伙伴', href: '#partners', active: false },
        { id: 'researchDirection', text: '研究方向', href: '#researchDirection', active: false },
        // { id: 'thesis', text: '研究成果', href: '#thesis', active: false },
        { id: 'researchFindings', text: '研究成果', href: '#researchFindings', active: false },
        { id: 'recruitment', text: '招生', href: '#recruitment', active: false },
        { id: 'contactus', text: '联系我们', href: '#contactus', active: false },
        // 其他导航链接...
      ],
      activeSection: 'home', // 默认激活的导航项
      isNavbarDark: false, // 控制导航栏背景颜色的变量
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);


  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scrollPosition = window.scrollY+80; // 增加50像素的缓冲区
      let isAnySectionActive = false;
      this.isNavbarDark = scrollPosition > 100; // 调整阈值以改变背景颜色
      const navbar = document.querySelector('.navbar');
      const navbarNav = document.querySelector('#navbarNav');
      // 页面滚动时自动收起导航栏
      if (scrollPosition > 100 && navbarNav.classList.contains('show')) {
        navbarNav.classList.remove('show');
      }

      this.navLinks.forEach(link => {
        const section = document.querySelector(link.href);
        if (section) {
          // 检查当前滚动位置是否在section的范围内
          const isActive = scrollPosition >= section.offsetTop && scrollPosition < section.offsetTop + section.offsetHeight;
          link.active = isActive;

          // 如果当前section是激活状态，设置标志位为true，并退出循环
          if (link.active) {
            isAnySectionActive = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
@import '@/assets/css/style.css';
@import '@/assets/css/responsive.css';
.header-bg {
  background-image: url('@/assets/img/实验室背景图.jpg');
  background-size: cover;
  padding: 100px 0; /* 确保导航栏有足够的空间 */
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 800px;
}
.header-bg:after{
  background: rgba(0,0,0,0.5);
  content: ' ';
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  height: 100%;
  width: 100%;
}
.navbar {
  height: 80px;
}
/* 导航栏在滚动时的背景颜色 */
.navbar.bg-dark {
  transition: background-color 0.3s;
}
.active{
  color: #56a440 !important;
}
.white{
  color: white;
  font-weight: bold;
}
.logo_container{
  display: flex;          /* 使用Flexbox布局 */
  align-items: center;    /* 垂直居中对齐子元素 */
  justify-content: flex-start; /* 水平开始对齐（默认值） */
}
.wyu_logo{
  max-width: 68px;
  max-height: 68px;
}
.logo{
  flex-shrink: 0; /* 保证子元素不会被压缩 */
}
.text-container {
  display: flex;          /* 文字容器也使用Flexbox布局 */
  flex-direction: column; /* 子元素垂直排列 */
  margin-left: 10px;      /* 与图片保持一定间距 */
  color: rgb(130, 183, 75);
}
</style>
