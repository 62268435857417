import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Home
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/teachers',
    name: 'teachers',
    redirect: '/',
  },
  {
    path: '/postgraduate',
    name: 'postgraduate',
    redirect: '/',
  },
  {
    path: '/researchDirection',
    name: 'researchDirection',
    redirect: '/',
  },
  {
    path: '/programs',
    name: 'programs',
    redirect: '/',
  },
  {
    path: '/partners',
    name: 'partners',
    redirect: '/',
  },
  {
    path: '/researchFindings',
    name: 'researchFindings',
    redirect: '/',
  },
  {
    path: '/thesis',
    name: 'thesis',
    redirect: '/',
  },
  {
    path: '/recruitment',
    name: 'recruitment',
    redirect: '/',
  },
  {
    path: '/contactus',
    name: 'contactus',
    redirect: '/',
  }
  // ,
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
